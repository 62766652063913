// 2. Include any default variable overrides here

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@400;700&display=swap');

$primary: #2990cc;
$secondary: #444c5f;
$success: #37b149;
$warning: #f6971b;
$error: #ff0000;
$info: #1298b3;
$light: #f1f2f3;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'warning': $warning,
  'error': $error,
  'info': $info,
  'light': $light
);

$enable-important-utilities: false;

$min-contrast-ratio: 2.5;

$btn-focus-box-shadow: 0;
$btn-focus-width: 0;
$btn-disabled-opacity: 0.65;

$mob-menu-btn-icon-size: 2.5rem;
$mob-menu-btn-icon-size-large: 4rem;

// Fonts
$font-name: var(--font-name, 'Roboto');
$font-base: $font-name;

// Font sizes
$fontsize-xlg: 22px;
$fontsize-lg: 18px;
$fontsize-md: 15px;
$fontsize-base: 14px;
$fontsize-sm: 12px;

// Theme colors
$color-brand-primary: var(--color-brand-primary, #11457f);
$color-brand-secondary: var(--color-brand-secondary, #00b5ed);

$color-primary: var(--color-base-primary, #2990cc);
$color-primary-light: var(--color-base-primary-light, #2d9ee0);
$color-primary-dark: var(--color-base-primary-dark, #2582b8);
$color-primary-text: var(--color-base-primary-text, #ffffff);

$color-secondary: var(--color-base-secondary, #444c5f);
$color-secondary-light: var(--color-base-secondary-light, #4b5469);
$color-secondary-dark: var(--color-base-secondary-dark, #3d4456);
$color-secondary-text: var(--color-base-secondary-text, #ffffff);

$color-success: var(--color-base-success, #37b149);
$color-success-light: var(--color-base-success-light, #3dc350);
$color-success-dark: var(--color-base-success-dark, #329f42);
$color-success-text: var(--color-base-success-text, #ffffff);

$color-warning: var(--color-base-warning, #f6971b);
$color-warning-light: var(--color-base-warning-light, #ffa61e);
$color-warning-dark: var(--color-base-warning-dark, #dd8818);
$color-warning-text: var(--color-base-warning-text, #444c5f);

$color-error: var(--color-base-error, #ff0000);
$color-error-light: var(--color-base-error-light, #ff0000);
$color-error-dark: var(--color-base-error-dark, #e60000);
$color-error-text: var(--color-base-error-text, #ffffff);

$color-info: var(--color-base-info, #1298b3);
$color-info-light: var(--color-base-info-light, #14a7c5);
$color-info-dark: var(--color-base-info-dark, #1089a1);
$color-info-text: var(--color-base-info-text, #ffffff);

$color-default: var(--color-base-default, #f5f5f5);
$color-default-light: var(--color-base-default-light, #ffffff);
$color-default-dark: var(--color-base-default-dark, #dddddd);
$color-default-text: var(--color-base-default-text, #444c5f);

$color-text-dark: var(--color-text-dark, #444c5f);
$color-text-light: var(--color-text-light, #fff);

$color-toolbar-start: var(--color-toolbar-start, #11457f);
$color-toolbar-end: var(--color-toolbar-end, #00b5ed);
$color-toolbar-text: var(--color-toolbar-text, #fff);

// Colors
$color-black: #000;
$color-black-20: rgba($color-black, 0.2);
$color-black-15: rgba($color-black, 0.15);
$color-black-14: rgba($color-black, 0.14);
$color-black-12: rgba($color-black, 0.12);
$color-black-05: rgba($color-black, 0.05);
$color-black-50: rgba($color-black, 0.5);
$color-white: #fff;
$color-white-20: rgba($color-white, 0.2);
$color-white-50: rgba($color-white, 0.5);
$color-white-70: rgba($color-white, 0.7);
$color-blue: #18a0fb;
$color-blue-70: rgba($color-blue, 0.7);
$color-blue-50: rgba($color-blue, 0.5);
$color-red: #ff1c41;
$color-red-80: rgba($color-red, 0.8);
$color-grey-darker: #494949;
$color-grey-dark: #808080;
$color-grey-dark-50: rgba($color-grey-dark, 0.5);
$color-grey-dark-40: rgba($color-grey-dark, 0.4);
$color-grey-medium: #cbccd1;
$color-grey: #e4e5e8;
$color-grey-light: #f1f2f3;
$color-grey-light-80: rgba($color-grey-light, 80);
$color-grey-lighter: #f7f7f7;
$color-workarea: #f5f5f5;
$color-croparea: #6cdbd8;

// Box shadow
$box-shadow-md: 0 3px 1px -2px $color-black-20, 0 2px 2px 0 $color-black-14, 0 1px 5px 0 $color-black-12;

// Border radius
$border-radius-sm: var(--border-radius-small, 2px);
$border-radius-md: var(--border-radius-medium, 5px);
$border-radius-lg: var(--border-radius-large, 50%);

// Element sizes
$navbar-height: 64px;
$nav-height-mob: 60px;

$toolbar-height-lt-md: 48px;
$toolbar-height-gt-sm: 64px;

$panels-width: 400px;

$page-nav-height-lt-md: 48px;
$page-nav-height-gt-sm: 80px;

$button-height-default: 35px;
$button-height-xsmall: 20px;
$button-height-small: 30px;
$button-height-large: 40px;
$button-height-xlarge: 50px;

$button-width-default: 35px;
$button-width-xsmall: 20px;
$button-width-small: 30px;
$button-width-large: 40px;
$button-width-xlarge: 50px;
