$font-icon: 'EditorFont';

$icon-hamburger: '\18100';
$icon-person: '\18101';
$icon-shopping-basket: '\18102';
$icon-shopping-cart: '\18103';
$icon-login: '\18104';
$icon-logout: '\18105';
$icon-visibility: '\18106';
$icon-visibility-off: '\18107';
$icon-check: '\18108';
$icon-apple-command: '\18109';
$icon-undo: '\18110';
$icon-redo: '\18111';
$icon-save: '\18112';
$icon-folder-open: '\18113';
$icon-content-cut: '\18114';
$icon-content-copy: '\18115';
$icon-content-paste: '\18116';
$icon-mode-edit: '\18117';
$icon-layers: '\18118';
$icon-layer-up: '\18119';
$icon-layer-down: '\18120';
$icon-layer-front: '\18121';
$icon-layer-back: '\18122';
$icon-grid-on: '\18123';
$icon-grid-off: '\18124';
$icon-add-circle: '\18125';
$icon-remove-circle: '\18126';
$icon-fullscreen: '\18127';
$icon-help: '\18128';
$icon-help-outline: '\18129';
$icon-info: '\18130';
$icon-info-outline: '\18131';
$icon-error: '\18132';
$icon-error-outline: '\18133';
$icon-arrow-back: '\18134';
$icon-arrow-forward: '\18135';
$icon-expand-less: '\18136';
$icon-expand-more: '\18137';
$icon-navigate-before: '\18138';
$icon-navigate-next: '\18139';
$icon-arrow-drop-up: '\18140';
$icon-arrow-drop-down: '\18141';
$icon-add: '\18142';
$icon-remove: '\18143';
$icon-close: '\18144';
$icon-cancel: '\18145';
$icon-format-align-left: '\18146';
$icon-format-align-center: '\18147';
$icon-format-align-right: '\18148';
$icon-format-align-justify: '\18149';
$icon-format-bold: '\18150';
$icon-format-italic: '\18151';
$icon-format-underlined: '\18152';
$icon-crop: '\18153';
$icon-cloud-upload: '\18154';
$icon-swap-horiz: '\18155';
$icon-swap-vert: '\18156';
$icon-photo-camera: '\18157';
$icon-panorama: '\18158';
$icon-reset-image: '\18159';
$icon-replay: '\18160';
$icon-stretch-h: '\18161';
$icon-stretch-v: '\18162';
$icon-delete: '\18163';
$icon-lock: '\18164';
$icon-lock-open: '\18165';
$icon-print-off: '\18166';
$icon-folder: '\18167';
$icon-font-download: '\18168';
$icon-color-reset: '\18169';
$icon-text-size: '\18170';
$icon-transparency: '\18171';
$icon-keyboard: '\18172';
$icon-print: '\18173';
$icon-export: '\18174';
$icon-import: '\18175';
$icon-background: '\18176';
$icon-check-circled: '\18177';
$icon-copy-off: '\18178';
$icon-reflect-h: '\18179';
$icon-reflect-v: '\18180';
$icon-scale-off: '\18181';
$icon-delete-off: '\18182';
$icon-move-off: '\18183';
$icon-rotate-off: '\18184';
$icon-crop-off: '\18185';
$icon-replace-off: '\18186';
$icon-reflect-off: '\18187';
$icon-cursor-pointer: '\18188';
$icon-untargetable: '\18189';
$icon-settings: '\18190';
$icon-search: '\18191';
$icon-trash-can-outline: '\18192';
$icon-font: '\18193';
$icon-line-spacing: '\18194';
$icon-zoom-in: '\18195';
$icon-brush: '\18196';
$icon-arrow-drop-left: '\18197';
$icon-arrow-drop-right: '\18198';
$icon-magic-wand: '\18199';
$icon-backspace: '\18200';
$icon-camera-diaphragm: '\18201';
$icon-more: '\18202';
$icon-force-portrait: '\18203';
$icon-chat: '\18204';
$icon-upload: '\18205';
$icon-move: '\18206';
$icon-untargetable-elements-targetable: '\18207';
$icon-pages-overview: '\18208';
$icon-high-gloss: '\18209';
