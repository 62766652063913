@import 'variables';

ed-toolbar .toolbar-menu-item {
  color: $color-toolbar-text !important;
}
ed-toolbar .top-menu-middle .shop-branding .shop-name {
  color: $color-toolbar-text !important;
}

.mat-expansion-panel-header {
  background-color: $color-secondary;
  border-radius: $border-radius-sm;
  &.mat-expanded {
    background-color: $color-secondary;
  }
}

.mat-expansion-panel-header-title {
  color: $color-secondary-text;
}

.mat-expansion-panel-header:focus,
.mat-expansion-panel-header:hover,
.mat-expansion-panel-header.mat-expanded:focus,
.mat-expansion-panel-header.mat-expanded:hover {
  background: $color-secondary-dark !important;
}

.mat-expansion-indicator::after {
  color: $color-secondary-text;
}
