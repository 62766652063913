@import 'mandatory-bootstrap-imports';
@import 'variables';
@import 'icons-editor';

$ed-icon-hamburger: $icon-hamburger;
$ed-icon-person: $icon-person;
$ed-icon-shopping-basket: $icon-shopping-basket;
$ed-icon-shopping-cart: $icon-shopping-cart;
$ed-icon-login: $icon-login;
$ed-icon-logout: $icon-logout;
$ed-icon-visibility: $icon-visibility;
$ed-icon-visibility-off: $icon-visibility-off;
$ed-icon-check: $icon-check;
$ed-icon-apple-command: $icon-apple-command;
$ed-icon-undo: $icon-undo;
$ed-icon-redo: $icon-redo;
$ed-icon-save: $icon-save;
$ed-icon-folder-open: $icon-folder-open;
$ed-icon-content-cut: $icon-content-cut;
$ed-icon-content-copy: $icon-content-copy;
$ed-icon-content-paste: $icon-content-paste;
$ed-icon-mode-edit: $icon-mode-edit;
$ed-icon-layers: $icon-layers;
$ed-icon-layer-up: $icon-layer-up;
$ed-icon-layer-down: $icon-layer-down;
$ed-icon-layer-front: $icon-layer-front;
$ed-icon-layer-back: $icon-layer-back;
$ed-icon-grid-on: $icon-grid-on;
$ed-icon-grid-off: $icon-grid-off;
$ed-icon-add-circle: $icon-add-circle;
$ed-icon-remove-circle: $icon-remove-circle;
$ed-icon-fullscreen: $icon-fullscreen;
$ed-icon-help: $icon-help;
$ed-icon-help-outline: $icon-help-outline;
$ed-icon-info: $icon-info;
$ed-icon-info-outline: $icon-info-outline;
$ed-icon-error: $icon-error;
$ed-icon-error-outline: $icon-error-outline;
$ed-icon-arrow-back: $icon-arrow-back;
$ed-icon-arrow-forward: $icon-arrow-forward;
$ed-icon-expand-less: $icon-expand-less;
$ed-icon-expand-more: $icon-expand-more;
$ed-icon-navigate-before: $icon-navigate-before;
$ed-icon-navigate-next: $icon-navigate-next;
$ed-icon-arrow-drop-up: $icon-arrow-drop-up;
$ed-icon-arrow-drop-down: $icon-arrow-drop-down;
$ed-icon-add: $icon-add;
$ed-icon-remove: $icon-remove;
$ed-icon-close: $icon-close;
$ed-icon-cancel: $icon-cancel;
$ed-icon-format-align-left: $icon-format-align-left;
$ed-icon-format-align-center: $icon-format-align-center;
$ed-icon-format-align-right: $icon-format-align-right;
$ed-icon-format-align-justify: $icon-format-align-justify;
$ed-icon-format-bold: $icon-format-bold;
$ed-icon-format-italic: $icon-format-italic;
$ed-icon-format-underlined: $icon-format-underlined;
$ed-icon-crop: $icon-crop;
$ed-icon-cloud-upload: $icon-cloud-upload;
$ed-icon-swap-horiz: $icon-swap-horiz;
$ed-icon-swap-vert: $icon-swap-vert;
$ed-icon-photo-camera: $icon-photo-camera;
$ed-icon-panorama: $icon-panorama;
$ed-icon-reset-image: $icon-reset-image;
$ed-icon-replay: $icon-replay;
$ed-icon-stretch-h: $icon-stretch-h;
$ed-icon-stretch-v: $icon-stretch-v;
$ed-icon-delete: $icon-delete;
$ed-icon-lock: $icon-lock;
$ed-icon-lock-open: $icon-lock-open;
$ed-icon-print-off: $icon-print-off;
$ed-icon-folder: $icon-folder;
$ed-icon-font-download: $icon-font-download;
$ed-icon-color-reset: $icon-color-reset;
$ed-icon-text-size: $icon-text-size;
$ed-icon-transparency: $icon-transparency;
$ed-icon-keyboard: $icon-keyboard;
$ed-icon-print: $icon-print;
$ed-icon-export: $icon-export;
$ed-icon-import: $icon-import;
$ed-icon-background: $icon-background;
$ed-icon-check-circled: $icon-check-circled;
$ed-icon-copy-off: $icon-copy-off;
$ed-icon-reflect-h: $icon-reflect-h;
$ed-icon-reflect-v: $icon-reflect-v;
$ed-icon-scale-off: $icon-scale-off;
$ed-icon-delete-off: $icon-delete-off;
$ed-icon-move-off: $icon-move-off;
$ed-icon-rotate-off: $icon-rotate-off;
$ed-icon-crop-off: $icon-crop-off;
$ed-icon-replace-off: $icon-replace-off;
$ed-icon-reflect-off: $icon-reflect-off;
$ed-icon-cursor-pointer: $icon-cursor-pointer;
$ed-icon-untargetable: $icon-untargetable;
$ed-icon-settings: $icon-settings;
$ed-icon-search: $icon-search;
$ed-icon-trash-can-outline: $icon-trash-can-outline;
$ed-icon-font: $icon-font;
$ed-icon-line-spacing: $icon-line-spacing;
$ed-icon-zoom-in: $icon-zoom-in;
$ed-icon-brush: $icon-brush;
$ed-icon-arrow-drop-left: $icon-arrow-drop-left;
$ed-icon-arrow-drop-right: $icon-arrow-drop-right;
$ed-icon-magic-wand: $icon-magic-wand;
$ed-icon-backspace: $icon-backspace;
$ed-icon-camera-diaphragm: $icon-camera-diaphragm;
$ed-icon-more: $icon-more;
$ed-icon-force-portrait: $icon-force-portrait;
$ed-icon-chat: $icon-chat;
$ed-icon-upload: $icon-upload;
$ed-icon-move: $icon-move;
$ed-icon-untargetable-elements-targetable: $icon-untargetable-elements-targetable;
$ed-icon-pages-overview: $icon-pages-overview;
$ed-icon-high-gloss: $icon-high-gloss;

[class*='ed-icon-'] {
  display: inline-block;
  font-family: $font-icon;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

.ed-icon-left {
  margin-right: 5px;
  @include media-breakpoint-up(lg, $grid-breakpoints) {
    margin-right: 8px;
  }
}
.ed-icon-right {
  margin-left: 5px;
  @include media-breakpoint-up(lg, $grid-breakpoints) {
    margin-left: 8px;
  }
}

.ed-icon-hamburger:before {
  content: $ed-icon-hamburger;
}
.ed-icon-person:before {
  content: $ed-icon-person;
}
.ed-icon-shopping-basket:before {
  content: $ed-icon-shopping-basket;
}
.ed-icon-shopping-cart:before {
  content: $ed-icon-shopping-cart;
}
.ed-icon-login:before {
  content: $ed-icon-login;
}
.ed-icon-logout:before {
  content: $ed-icon-logout;
}
.ed-icon-visibility:before {
  content: $ed-icon-visibility;
}
.ed-icon-visibility-off:before {
  content: $ed-icon-visibility-off;
}
.ed-icon-check:before {
  content: $ed-icon-check;
}
.ed-icon-apple-command:before {
  content: $ed-icon-apple-command;
}
.ed-icon-undo:before {
  content: $ed-icon-undo;
}
.ed-icon-redo:before {
  content: $ed-icon-redo;
}
.ed-icon-save:before {
  content: $ed-icon-save;
}
.ed-icon-folder-open:before {
  content: $ed-icon-folder-open;
}
.ed-icon-content-cut:before {
  content: $ed-icon-content-cut;
}
.ed-icon-content-copy:before {
  content: $ed-icon-content-copy;
}
.ed-icon-content-paste:before {
  content: $ed-icon-content-paste;
}
.ed-icon-mode-edit:before {
  content: $ed-icon-mode-edit;
}
.ed-icon-layers:before {
  content: $ed-icon-layers;
}
.ed-icon-layer-up:before {
  content: $ed-icon-layer-up;
}
.ed-icon-layer-down:before {
  content: $ed-icon-layer-down;
}
.ed-icon-layer-front:before {
  content: $ed-icon-layer-front;
}
.ed-icon-layer-back:before {
  content: $ed-icon-layer-back;
}
.ed-icon-grid-on:before {
  content: $ed-icon-grid-on;
}
.ed-icon-grid-off:before {
  content: $ed-icon-grid-off;
}
.ed-icon-add-circle:before {
  content: $ed-icon-add-circle;
}
.ed-icon-remove-circle:before {
  content: $ed-icon-remove-circle;
}
.ed-icon-fullscreen:before {
  content: $ed-icon-fullscreen;
}
.ed-icon-help:before {
  content: $ed-icon-help;
}
.ed-icon-help-outline:before {
  content: $ed-icon-help-outline;
}
.ed-icon-info:before {
  content: $ed-icon-info;
}
.ed-icon-info-outline:before {
  content: $ed-icon-info-outline;
}
.ed-icon-error:before {
  content: $ed-icon-error;
}
.ed-icon-error-outline:before {
  content: $ed-icon-error-outline;
}
.ed-icon-arrow-back:before {
  content: $ed-icon-arrow-back;
}
.ed-icon-arrow-forward:before {
  content: $ed-icon-arrow-forward;
}
.ed-icon-expand-less:before {
  content: $ed-icon-expand-less;
}
.ed-icon-expand-more:before {
  content: $ed-icon-expand-more;
}
.ed-icon-navigate-before:before {
  content: $ed-icon-navigate-before;
}
.ed-icon-navigate-next:before {
  content: $ed-icon-navigate-next;
}
.ed-icon-arrow-drop-up:before {
  content: $ed-icon-arrow-drop-up;
}
.ed-icon-arrow-drop-down:before {
  content: $ed-icon-arrow-drop-down;
}
.ed-icon-add:before {
  content: $ed-icon-add;
}
.ed-icon-remove:before {
  content: $ed-icon-remove;
}
.ed-icon-close:before {
  content: $ed-icon-close;
}
.ed-icon-cancel:before {
  content: $ed-icon-cancel;
}
.ed-icon-format-align-left:before {
  content: $ed-icon-format-align-left;
}
.ed-icon-format-align-center:before {
  content: $ed-icon-format-align-center;
}
.ed-icon-format-align-right:before {
  content: $ed-icon-format-align-right;
}
.ed-icon-format-align-justify:before {
  content: $ed-icon-format-align-justify;
}
.ed-icon-format-bold:before {
  content: $ed-icon-format-bold;
}
.ed-icon-format-italic:before {
  content: $ed-icon-format-italic;
}
.ed-icon-format-underlined:before {
  content: $ed-icon-format-underlined;
}
.ed-icon-crop:before {
  content: $ed-icon-crop;
}
.ed-icon-cloud-upload:before {
  content: $ed-icon-cloud-upload;
}
.ed-icon-swap-horiz:before {
  content: $ed-icon-swap-horiz;
}
.ed-icon-swap-vert:before {
  content: $ed-icon-swap-vert;
}
.ed-icon-photo-camera:before {
  content: $ed-icon-photo-camera;
}
.ed-icon-panorama:before {
  content: $ed-icon-panorama;
}
.ed-icon-reset-image:before {
  content: $ed-icon-reset-image;
}
.ed-icon-replay:before {
  content: $ed-icon-replay;
}
.ed-icon-stretch-h:before {
  content: $ed-icon-stretch-h;
}
.ed-icon-stretch-v:before {
  content: $ed-icon-stretch-v;
}
.ed-icon-delete:before {
  content: $ed-icon-delete;
}
.ed-icon-lock:before {
  content: $ed-icon-lock;
}
.ed-icon-lock-open:before {
  content: $ed-icon-lock;
}
.ed-icon-print-off:before {
  content: $ed-icon-print-off;
}
.ed-icon-folder:before {
  content: $ed-icon-folder;
}
.ed-icon-font-download:before {
  content: $ed-icon-font-download;
}
.ed-icon-color-reset:before {
  content: $ed-icon-color-reset;
}
.ed-icon-text-size:before {
  content: $ed-icon-text-size;
}
.ed-icon-transparency:before {
  content: $ed-icon-transparency;
}
.ed-icon-keyboard:before {
  content: $ed-icon-keyboard;
}
.ed-icon-print:before {
  content: $ed-icon-print;
}
.ed-icon-export:before {
  content: $ed-icon-export;
}
.ed-icon-import:before {
  content: $ed-icon-import;
}
.ed-icon-background:before {
  content: $ed-icon-background;
}
.ed-icon-check-circled:before {
  content: $ed-icon-check-circled;
}
.ed-icon-copy-off:before {
  content: $ed-icon-copy-off;
}
.ed-icon-reflect-h:before {
  content: $ed-icon-reflect-h;
}
.ed-icon-reflect-v:before {
  content: $ed-icon-reflect-v;
}
.ed-icon-scale-off:before {
  content: $ed-icon-scale-off;
}
.ed-icon-delete-off:before {
  content: $ed-icon-delete-off;
}
.ed-icon-move-off:before {
  content: $ed-icon-move-off;
}
.ed-icon-rotate-off:before {
  content: $ed-icon-rotate-off;
}
.ed-icon-crop-off:before {
  content: $ed-icon-crop-off;
}
.ed-icon-replace-off:before {
  content: $ed-icon-replace-off;
}
.ed-icon-reflect-off:before {
  content: $ed-icon-reflect-off;
}
.ed-icon-cursor-pointer:before {
  content: $ed-icon-cursor-pointer;
}
.ed-icon-untargetable:before {
  content: $ed-icon-untargetable;
}
.ed-icon-settings:before {
  content: $ed-icon-settings;
}
.ed-icon-search:before {
  content: $ed-icon-search;
}
.ed-icon-trash-can-outline:before {
  content: $ed-icon-trash-can-outline;
}
.ed-icon-font:before {
  content: $ed-icon-font;
}
.ed-icon-line-spacing:before {
  content: $ed-icon-line-spacing;
}
.ed-icon-zoom-in:before {
  content: $ed-icon-zoom-in;
}
.ed-icon-brush:before {
  content: $ed-icon-brush;
}
.ed-icon-arrow-drop-left:before {
  content: $ed-icon-arrow-drop-left;
}
.ed-icon-arrow-drop-right:before {
  content: $ed-icon-arrow-drop-right;
}
.ed-icon-magic-wand:before {
  content: $ed-icon-magic-wand;
}
.ed-icon-backspace:before {
  content: $ed-icon-backspace;
}
.ed-icon-camera-diaphragm:before {
  content: $ed-icon-camera-diaphragm;
}
.ed-icon-more:before {
  content: $ed-icon-more;
}
.ed-icon-force-portrait:before {
  content: $ed-icon-force-portrait;
}
.ed-icon-chat:before {
  content: $ed-icon-chat;
}
.ed-icon-upload:before {
  content: $ed-icon-upload;
}
.ed-icon-move:before {
  content: $ed-icon-move;
}
.ed-icon-untargetable-elements-targetable:before {
  content: $ed-icon-untargetable-elements-targetable;
}
.ed-icon-pages-overview:before {
  content: $ed-icon-pages-overview;
}
.ed-icon-high-gloss:before {
  content: $ed-icon-high-gloss;
}

.ng-fa-icon {
  font-size: 1rem;
}

.fa-pull-left {
  margin-right: map-get($spacers, 2) !important;
}

.fa-pull-right {
  margin-left: map-get($spacers, 2) !important;
}
