$font-path-ttf: '../fonts/EditorFont.ttf';
$font-path-woff: '../fonts/EditorFont.woff';
$font-path-svg: '../fonts/EditorFont.svg';

@font-face {
  font-family: 'EditorFont';
  src: url($font-path-ttf) format('truetype'), url($font-path-woff) format('woff'), url($font-path-svg) format('svg');
  font-weight: normal;
  font-style: normal;
}
