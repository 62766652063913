@import 'mandatory-bootstrap-imports';
@import 'variables';
@import 'icons';
@import 'editor-font';
@import 'ie-css-vars-fallback';

html,
body,
div {
  border: 0;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

/* Box-sizing border-box */
* {
  box-sizing: border-box;
}

/* Set up a default font and some padding to provide breathing room */
body {
  font-family: $font-base;
  font-size: $fontsize-base;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
textarea,
select,
button {
  font: 400 14px $font-base;
}
input {
  height: 30px;
}

input,
textarea {
  border: 1px solid $color-grey;
  padding: 5px;
}

input[type='range'] {
  border: none;
  min-width: 160px;
}

/* hide up/down arrows ("spinners") on input fields marked type="number" */
.no-spinners {
  -moz-appearance: textfield;
}

.no-spinners::-webkit-outer-spin-button,
.no-spinners::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

p {
  margin: 0;
}

button:focus {
  outline: none;
}

a:focus-visible {
  outline: none;
}

/* styling for material elements in the app.
   Must be here, because only from here the mat elements in the DOM can be reached */
.mat-dialog-container {
  height: inherit !important;
  max-height: 95vh !important;
}

.mat-tooltip {
  background-color: $color-secondary;
  font-size: 14px;
  line-height: 18px;
  color: $color-grey;
  padding: 9px 0;
  border-radius: $border-radius-sm;
  display: block;
  overflow: visible !important;
  position: relative;
  &::before {
    position: absolute;
    content: '';
    display: block;
    z-index: 1;
    width: 18px;
    height: 8px;
    background-repeat: no-repeat;
    background-position: bottom center;
  }
  &.below {
    &::before {
      top: -8px;
      left: calc(50% - 9px);
      background-image: url('../svg/tooltip-arrow-up.svg');
    }
  }
  &.left-below {
    &::before {
      top: -8px;
      left: 6px;
      background-image: url('../svg/tooltip-arrow-up.svg');
    }
  }
  &.right-below {
    &::before {
      top: -8px;
      right: 6px;
      background-image: url('../svg/tooltip-arrow-up.svg');
    }
  }
  &.above {
    &::before {
      left: calc(50% - 9px);
      bottom: -8px;
      background-image: url('../svg/tooltip-arrow-down.svg');
    }
  }
  &.left {
    &::before {
      width: 8px;
      height: 18px;
      right: -7px;
      top: calc(50% - 9px);
      background-image: url('../svg/tooltip-arrow-right.svg');
    }
  }
}

.mat-drawer {
  color: $color-text-dark !important;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: transparent;
}

button.mat-menu-item,
a.mat-menu-item {
  font-size: $fontsize-base;
  height: 36px;
  line-height: 36px;
  font-family: $font-name;
}

.mat-checkbox-inner-container {
  height: 12px !important;
  width: 12px !important;
}
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $color-secondary !important;
}

.mat-checkbox-layout .mat-checkbox-label {
  font-family: $font-name;
}

.mat-expansion-panel-body {
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 0;
}

.mat-expansion-panel-content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
  font-family: $font-name;
}

.mat-expansion-panel-header {
  font-family: $font-base;
}

.mat-expansion-panel-header:not(.mat-expanded) {
  .mat-expansion-indicator {
    transform: translateY(-2px) !important;
  }
}

.mat-expansion-panel.mat-expanded {
  height: 100%;
}

.mat-expansion-panel {
  color: $color-text-dark;
}

.panel-content {
  margin: 0 -28px 0 -18px;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  overflow-y: auto;
}

.mat-error {
  color: $color-error;
}

.mat-radio-container,
.mat-radio-outer-circle,
.mat-radio-inner-circle {
  height: 16px !important;
  width: 16px !important;
}

.mat-radio-group {
  width: 100%;
  padding-bottom: 20px;
  display: inline-block;
}
.mat-radio-button {
  padding-bottom: 0;
}

.mat-form-field {
  width: 100%;
  padding-bottom: 15px;
  font-family: $font-name;
}
.mat-form-field-wrapper {
  padding-bottom: 0;
}
.mat-form-field-infix {
  border-top: none;
  padding: 0;
}
.mat-form-field-appearance-standard .mat-form-field-flex {
  padding-top: 0 !important;
}
.mat-form-field-appearance-standard .mat-form-field-underline {
  display: none;
}
.mat-form-field-subscript-wrapper {
  position: relative !important;
}
.mat-form-field-appearance-standard .mat-select-arrow-wrapper {
  transform: translateY(0) !important;
}

.mat-form-field-empty {
  top: 50%;
  left: 10px !important;
}

.mat-error {
  color: $color-error;
}

.mat-select {
  height: 35px;
  border: 1px solid $color-grey;
  border-radius: $border-radius-sm;
  padding: 0 10px;
}

.mat-select-trigger {
  height: 35px;
}

.mat-select-value {
  height: 35px;
  line-height: 35px;
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  @include media-breakpoint-down(lg, $grid-breakpoints) {
    border-color: $color-text-light !important;
  }
  border-color: $color-grey-medium !important;
}
.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
  @include media-breakpoint-down(lg, $grid-breakpoints) {
    background-color: $color-text-light !important;
  }
}
@include media-breakpoint-up(lg, $grid-breakpoints) {
  .mat-slider-horizontal {
    height: 30px !important;
  }
  .mat-slider-wrapper {
    top: 15px !important;
    left: 3px !important;
    right: 3px !important;
  }
}

.mat-slider-horizontal .mat-slider-ticks {
  @include media-breakpoint-down(lg, $grid-breakpoints) {
    background-image: repeating-linear-gradient(
      to right,
      $color-white-70,
      $color-white-70 2px,
      transparent 0,
      transparent
    );
    background-image: -moz-repeating-linear-gradient(
      0.0001deg,
      $color-white-70,
      $color-white-70 2px,
      transparent 0,
      transparent
    );
  }
}
.mat-slider-track-background {
  @include media-breakpoint-down(lg, $grid-breakpoints) {
    background-color: $color-white-20;
  }
}

/* snack bar should have a viewContainerRef to the design area,
 * for the proper position and animation instead of position absolute
 */
.save-snack-bar {
  background-color: $color-white;
  box-shadow: $box-shadow-md;
  @include media-breakpoint-up(lg, $grid-breakpoints) {
    left: 50px;
  }
}

.mat-snack-bar-container {
  color: $color-secondary;
  min-width: auto !important;
  margin-top: 100px !important;
  margin-left: 10px !important;
  @include media-breakpoint-down(lg, $grid-breakpoints) {
    margin-top: 80px !important;
    margin-left: 7px !important;
  }
}

.mat-snack-bar-handset {
  @include media-breakpoint-down(lg, $grid-breakpoints) {
    width: auto !important;
  }
}

.mat-snack-bar-handset .mat-snack-bar-container {
  width: auto !important;
}

.mat-simple-snackbar {
  display: block;
  &:before {
    content: $icon-check;
    color: $color-success;
    font-size: $fontsize-lg;
    font-family: $font-icon;
    margin-right: 8px;
  }
}

.mat-input-element {
  box-sizing: border-box !important;
}

.loader {
  display: inline-block;
  font-size: 4px;
  margin: 5px 30px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em,
      -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em,
      -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0,
      -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0,
      -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0,
      -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0,
      -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em,
      -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em,
      -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0,
      -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0,
      -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0,
      -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0,
      -2em -2em 0 0.2em;
  }
}

#auth-dialog,
#auth-dialog-from-recover-dialog,
#auth-dialog-from-register-dialog,
#auth-dialog-after-recover,
#auth-dialog-from-save-dialog,
#auth-dialog-from-continue-dialog,
#register-dialog,
#register-dialog-from-save-dialog,
#register-dialog-from-continue-dialog,
#register-success-dialog,
#recover-password-dialog,
#recover-password-success-dialog,
#move-foil-element-dialog,
#set-foil-element-dialog,
#multiple-special-colors-dialog {
  width: 300px;
  @include media-breakpoint-up(lg, $grid-breakpoints) {
    width: 360px;
  }
}

#color-list-dialog,
.context-menu-dialog .mat-dialog-container {
  padding: 0;
}

#color-menu-dialog,
#font-menu-dialog {
  border-radius: 0;
  padding: 0;
  @include media-breakpoint-up(lg, $grid-breakpoints) {
    border-radius: $border-radius-md;
  }
}

.text-cursor-blink {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: text-cursor-blink-fadeoutin;
}

@keyframes text-cursor-blink-fadeoutin {
  from {
    opacity: 1;
  }
  13% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  63% {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}

.renderer ::selection {
  background-color: transparent;
}

.renderer ::-moz-selection {
  background-color: transparent;
}

.fake-text-input {
  position: absolute;
  width: 0;
  height: 0;
  border: none;
  margin: 0;
  padding: 0;
}
