@use '@angular/material' as mat;
@import 'variables';
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$color-primary-map: (
  50: #eff4fb,
  100: #dee8f6,
  200: #cddcf1,
  300: #bbd1ed,
  400: #a8c5e7,
  500: #95bae2,
  600: #80afdd,
  700: #69a4d7,
  800: #4e9ad2,
  900: $color-primary,
  A100: #c2ffff,
  A200: #76ddff,
  A400: #4fb6f2,
  A700: $color-primary,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$editor-app-primary: mat.define-palette($color-primary-map);
$editor-app-accent: mat.define-palette($color-primary-map, A700, A100, A200);

// The warn palette is optional (defaults to red).
$editor-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$editor-app-theme: mat.define-light-theme($editor-app-primary, $editor-app-accent, $editor-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($editor-app-theme);

/* You can add global styles to this file, and also import other style files */
/* Reset */
